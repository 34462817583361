
//name
export const SET_NAME = 'SET_NAME'

//people
export const ADD_PERSON = 'ADD_PERSON'
export const SET_PEOPLE = 'SET_PEOPLE'

//item
export const ADD_ITEM = 'ADD_ITEM'
export const UPDATE_ITEM = 'UPDATE_ITEM'

//appState
export const SELECT_ITEM = 'SELECT_ITEM'